
<i18n locale="th" lang="yaml" >
select.part.placeholder.default : "เลือกอะไหล่ในระบบ"
select.part.custom.placeholder.default : "เพิ่มอะไหล่ที่ไม่มีในระบบ"
select.part.mode : "เปลี่ยนรูปแบบการกรอกอะไหล่"
select.part.button_text : "กดเพื่อเลือกอะไหล่ในระบบ"
</i18n>

<template>
	<div class="myselect-part-custom">
		<a-button v-if="isOnsite"
		:icon="selectedPart.name ? 'none' : 'select'"
		@click="() => handleShowModal(true)">
			<ImageLoader
					v-if="selectedPart.name"
					:src="selectedPart.image_url"
					class="mycard-image"
					size="tiny"
					border-type="round">
					<a-icon type="deployment-unit" />
			</ImageLoader>
			{{ displayButtonName }}
		</a-button>
		<ServicePartListModal
		:visible="visible"
		:on-hide-modal="() => handleShowModal(false)"
		:parts="partOptions"
		:on-select-part="handlePartChange" />
		<MySelect
			v-if="!isOnsite"
			v-show="mode != 'custom'"
			ref="partSelectRef"
			:pagination="pagination"
			class="myselect-part-input"
			:dropdown-class-name="dropdownCss"
			option-label-prop="label"
			:placeholder="cPlaceholder"
			:no-max-height="true"
			page-position="bottom"
			:value="cSelectedPartId"
			@change="handlePartChange"
			@clear-search="clearSearchParts"
			@search="searchParts">
			<a-select-option v-for="part in partResults" :key="part.id"
				:value="part.id" :label="part.name">
				<PartSelectCard :part="part" />
			</a-select-option>
		</MySelect>
		<a-input v-show="mode == 'custom'" ref="partInputRef" key="part_input_text"
			class="myselect-part-input"
			:value="customPart"
			:placeholder="cCustomPlaceholder"
			@change="handlePartCustomChange" />
	</div>
</template>

<script>
import PartSelectCard from "@components/part/PartSelectCard.vue"
import {Select} from "ant-design-vue"
import {arrayPagination} from "@utils/objectUtil"
import {wrapText} from "@utils/stringUtil"
import MySelect from "@components/input/MySelect.vue"
import { mapGetters, mapState } from 'vuex'
import _get from "lodash/get"
import ServicePartListModal from "@components/service/ServicePartListModal.vue"
import ImageLoader from "@components/common/ImageLoader.vue"

export default {
	components : {
		ImageLoader,
		ServicePartListModal,
		"a-select-option" : Select.Option,
		PartSelectCard , MySelect
	} ,
	model : {
		prop : 'value',
		event : 'change'
	} ,
	props  : {
		value : {
			type : null,
			default : () => []
		} ,
		selectableOnly : {
			type : Boolean,
			default : false,
		} ,
		activePartsOnly : {
			type : Boolean,
			default : false,
		} ,
		excludedPartIdList : {
			type : Array,
			default : () => []
		} ,
		placeholder : {
			type : String,
			default : undefined,
		} ,
		customPlaceholder : {
			type : String,
			default : undefined
		},
		isOnsite: {
			type : Boolean,
			default : false,
		},
	} ,
	data() {
		return {
			mode : 'custom' ,
			customPart : undefined,
			selectedPart : {},
			pagination : {
				pageSize : 5 ,
				current : 1,
				total : 0,
			} ,
			partResults : [],
			visible: false,
		}
	} ,
	computed : {
		...mapState('drone',['parts']) ,
		...mapGetters('drone',['getPartById']) ,
		displayButtonName(){
			return this.selectedPart.name ? wrapText(this.selectedPart.name) : this.$t('select.part.button_text')
		},
		dropdownCss() {
			return "myselect-dropdown-border myselect-part-dropdown myselect-dropdown-sm " + (this.mode !='select' ? 'hidden' : '')
		} ,
		cIcon() {
			return this.mode == 'select' ? 'edit' : 'database'
		} ,
		cPlaceholder() {
			return this.$notEmpty(this.placeholder) ? this.placeholder : this.$t('select.part.placeholder.default')
		} ,
		cCustomPlaceholder() {
			return this.$notEmpty(this.customPlaceholder) ? this.customPlaceholder : this.$t('select.part.custom.placeholder.default')
		} ,
		cSelectedPartId() {
			return _get(this.selectedPart,'id',undefined)
		} ,
		partOptions() {
			return this.parts.filter((part) => {
				return !this.excludedPartIdList.includes(part.id)
			});
		} ,
	} ,
	watch: {
		value : {
			handler() {
				this.handleValueChange()
			} ,
			deep : true
		}
	} ,
	mounted() {
		this.handleValueChange()
	} ,
	methods : {
		handleShowModal(visible = false){
			this.visible = visible;
		},
		handleValueChange() {
			const mode = _get(this.value,'mode')
			if (mode == 'custom') {
				this.mode = 'custom'
				this.selectedPart = {}
				this.customPart = _get(this.value,'part_name')
			} else if (mode == 'select') {
				this.mode = 'select'
				const partId = _get(this.value,'part_id')
				this.selectedPart = this.getPartById(partId)
				if (this.selectedPart.id) {
					if (this.partResults.findIndex((p)=> p.id == this.selectedPart.id) == -1) {
						this.partResults = [{...this.selectedPart}]
						this.pagination.current = 1
						this.pagination.total = 1
					}
				}
				this.customPart = undefined
			} else {
				this.reset()
			}
		} ,
		handlePartChange(value) {
			this.selectedPart = this.getPartById(value)
			this.emitChange()
		} ,
		handlePartCustomChange(event) {
			this.customPart = event.target.value
			this.emitChange()
		} ,
		changeMode() {
			this.selectedPart = {}
			this.customPart = undefined
			if (this.mode == 'custom') {
				this.mode = 'select'
				this.$nextTick(() => {
					this.$refs.partSelectRef.focus()
				})
			} else{
				this.mode = 'custom'
				this.$nextTick(() => {
					this.$refs.partInputRef.focus()
				})
			}
			this.emitChange()
		} ,
		reset() {
			this.mode = 'select'
			this.partSelectId = undefined
			this.partCustom = undefined
			this.emitChange()
		} ,
		emitChange() {
			if (this.mode == 'custom') {
				this.$emit('change',{
					mode : this.mode,
					part_name : this.customPart ,
					part : {},
				})
			} else {
				this.$emit('change',{
					mode : this.mode,
					part_id : this.selectedPart.id ,
					part : {...this.selectedPart}
				})
			}
		} ,
		clearSearchParts() {
			this.searchParts({search : '',page : 1})
		} ,
		searchParts(searchEvent) {
			const keyword = searchEvent.search.toLowerCase()
			const searchResult = this.$notEmpty(keyword) ? this.partOptions.filter((p) => p.searchContent.indexOf(keyword) >= 0) : this.partOptions
			this.partResults = arrayPagination(searchResult,searchEvent.page,this.pagination.pageSize)
			this.pagination.current = searchEvent.page
			this.pagination.total = searchResult.length
		}
	}
}
</script>

<style lang="less" scoped>
.myselect-part-custom{
	display : flex;
	flex-wrap : nowrap;
}
.myselect-part-btn {
	display : block;
}
.myselect-part-input {
	width : 205px;
}
.mycard-image{
	width: 20px;
	height: 20px;
	margin-right: 5px;
}
</style>

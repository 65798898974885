<script>
import { mapState } from 'vuex'
export default {
	computed : {
		...mapState('drone', {droneParts: state => state.parts}),
	} ,
	methods : {
		getDronePart(partId) {
    	return this.droneParts.find(part => part.id === partId)
  	},
	}
}
</script>

<i18n locale="th" lang="yaml" src="@i18n/service/claim.th.yaml">
</i18n>
<i18n locale="th" lang="yaml" >
claim.field.detail.placeholder : "เช่น ขณะขึ้นบินมีอาการสั่น ซึ่งน่าจะเป็นจากการทำงานผิดปกติของ Firmware เป็นต้น"
claim.field.analysis.placeholder : "เช่น จากการตรวจสอบ Log แล้วพบความผิดปกติจริง หรือ ยังไม่พบข้อบ่งชัด"
claim.field.reason.placeholder : "ผลการประเมินจากหลักฐาน"
claim.field.remark.placeholder : "หมายเหตุเพิ่มเติม ในการขอเคลม โดยส่วนนี้จะไม่ระบุในเอกสารใบเคลม เช่น เครื่องนี้พบปัญหานี้บ่อยมาก เลยขอพิจารณาการเคลมเป็นพิเศษ เป็นต้น"

claim.detail : "รายละเอียดการขอเคลม"

claim.reason.positive : "Approved"
claim.reason.positive.1 : "ความผิดพลาดจากระบบ"
claim.reason.positive.2 : "ความผิดพลาดจากการผลิต"
claim.reason.positive.3 : "ความผิดพลาดจากอะไหล่"
claim.reason.negative : "Rejected"
claim.reason.negative.1 : "ไม่เข้าเงื่อนไขการเคลม"
claim.reason.negative.2 : "ความผิดพลาดจากลูกค้า"
claim.reason.negative.3 : "ความผิดพลาดของช่างซ่อม"
</i18n>

<template>
	<div class="claim-request-detail-container">
		<div class="mytab-section-title">
			{{$t('claim.detail')}}
		</div>

		<div class="claim-subsection claim-detail">
			<div class="claim-row">
				<label>{{$t('claim.field.detail')}} *</label>
				<div v-if="canUpdateRequest" class="claim-input">
					<a-textarea
						v-model="requestDetailValue.detail"
						class="claim-limit-width"
						:auto-size="{ minRows: 3,maxRows: 5 }"
						:placeholder="$t('claim.field.detail.placeholder')"/>
				</div>
				<div v-else class="claim-value">
					<MyTextAreaView :value="requestDetailValue.detail"
						class="claim-limit-width"/>
				</div>
			</div>
			<template v-if="canViewDecision">
				<div class="claim-row claim-row-sameline claim-reason">
					<label>{{$t('claim.field.reason')}} *</label>
					<div v-if="canUpdateDecision" class="claim-input">
						<a-auto-complete
							v-model="requestDetailValue.reason"
							class="reason-input"
							option-label-prop="value"
							:placeholder="$t('claim.field.reason.placeholder')"
							allow-clear
							dropdown-class-name="reason-dropdown"
							:get-popup-container="getPopupContainer">
							<template slot="dataSource">
								<a-select-opt-group v-for="group in resultReasons" :key="group.title"
									class="reason-group">
									<span slot="label">
										{{group.title}}
									</span>
									<a-select-option v-for="item in group.items" :key="item" :value="item">
										{{item}}
									</a-select-option>
								</a-select-opt-group>
							</template>
						</a-auto-complete>
					</div>
					<div v-else class="claim-value">
						{{requestDetailValue.reason | emptyCheck}}
					</div>
				</div>

				<div class="claim-row">
					<label>{{$t('claim.field.analysis')}} *</label>
					<div v-if="canUpdateDecision" class="claim-input">
						<a-textarea
							v-model="requestDetailValue.analysis"
							class="claim-limit-width"
							:auto-size="{ minRows: 3,maxRows: 5 }"
							:placeholder="$t('claim.field.analysis.placeholder')"/>
					</div>
					<div v-else class="claim-value">
						<MyTextAreaView :value="requestDetailValue.analysis"
							class="claim-limit-width"/>
					</div>
				</div>
			</template>

			<div v-if="canViewRemark" class="claim-row">
				<label>{{$t('claim.field.remark')}}</label>
				<div v-if="canUpdateRequest || canUpdateDecision" class="claim-input">
					<a-textarea
						v-model="requestDetailValue.remark"
						class="claim-limit-width"
						:auto-size="{ minRows: 3,maxRows: 5 }"
						:placeholder="$t('claim.field.remark.placeholder')"/>
				</div>
				<div v-else class="claim-value">
					<MyTextAreaView :value="requestDetailValue.remark"
						class="claim-limit-width"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PopupMixin from "@mixins/PopupMixin.vue"
import {AutoComplete,Select,Input} from "ant-design-vue"
import MyTextAreaView from "@components/common/MyTextAreaView.vue"
import {replaceProperty} from "@utils/objectUtil"

export default {
	components : {
		"a-textarea" : Input.TextArea, "a-auto-complete" : AutoComplete,
		"a-select-opt-group" : Select.OptGroup , "a-select-option" : Select.Option ,
		MyTextAreaView ,
	} ,
	mixins : [PopupMixin] ,
	props : {
		service : {
			type : null,
			default : () => []
		} ,
		canUpdateRequest : {
			type : Boolean,
			default : false,
		} ,
		canUpdateDecision : {
			type : Boolean,
			default : false,
		} ,
		canViewDecision : {
			type : Boolean,
			default : false,
		} ,
		canViewRemark : {
			type : Boolean,
			default : false,
		}
	} ,
	data() {
		return {
			requestDetailValue : {
				detail : undefined,
				analysis : undefined,
				reason : undefined,
				remark : undefined,
			} ,
		}
	} ,
	computed : {
		resultReasons() {
			return [
				{
					title : this.$t('claim.reason.positive') ,
					items : [
						this.$t('claim.reason.positive.1') ,
						this.$t('claim.reason.positive.2') ,
						this.$t('claim.reason.positive.3') ,
					] ,
				} ,
				{
					title : this.$t('claim.reason.negative') ,
					items : [
						this.$t('claim.reason.negative.1') ,
						this.$t('claim.reason.negative.2') ,
						this.$t('claim.reason.negative.3') ,
					] ,
				}
			]
		} ,
	} ,
	methods : {
		setData(claimRequest) {
			replaceProperty(this.requestDetailValue,claimRequest)
		} ,
		getData() {
			return {
				...this.requestDetailValue,
			}
		}
	} ,
}
</script>

<style lang="less" scoped>
@import 'claim.less';
.reason-input {
	width : 250px;
}

.claim-row-sameline.claim-reason {
	> label {
		text-align : left;
		width : auto;
	}
	.claim-value {
		font-size : 1.1em;
		color : @primary-color;
		font-weight: 600;
	}
}
</style>
<style lang="less">
.reason-dropdown {
	.ant-select-dropdown-menu-vertical {
		padding : 0;
		max-height: none;
	}
}
.reason-group {
	.ant-select-dropdown-menu-item-group-title {
		background : @gray-4;
		color : @gray-7;
		font-size : 14px;
	}
}
</style>

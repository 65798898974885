<i18n locale="th" lang="yaml" >
ticket_comment.submit.label : "เพิ่ม Comment"

ticket_resolved.is_resolved : "สามารถแก้ปัญหาเรียบร้อย (Ticket is resolved)"
ticket_resolved.reason.label : "ผลการวิเคราะห์"
ticket_resolved.result.label : "ผลการประเมิน"
ticket_resolved.reason.positive : "Positive - สาเหตุพิจารณาเปิดเคลมได้"
ticket_resolved.reason.positive.1 : "ความผิดพลาดจากระบบ"
ticket_resolved.reason.positive.2 : "ความผิดพลาดจากการผลิต"
ticket_resolved.reason.positive.3 : "ความผิดพลาดจากอะไหล่"
ticket_resolved.reason.negative : "Negative - หลักฐานไม่พอเปิดเคลม"
ticket_resolved.reason.negative.1 : "ไม่เข้าเงื่อนไขการเคลม"
ticket_resolved.reason.negative.2 : "ความผิดพลาดจากลูกค้า"
ticket_resolved.reason.negative.3 : "ความผิดพลาดของช่างซ่อม"

ticket_resolved.general.reason : "ปัญหาได้รับการแก้ไข"
</i18n>

<template>
	<div>
		<div class="comment-list">
			<component
				:is="commentComponent(commentData)" v-for="commentData in commentDataList"
				v-show="!(hideSystemMessage && commentData.comment.is_system)"
				:key="commentData.id"
				:comment="commentData.comment"
				:user="commentData.createdBy.user"
				:company="commentData.createdBy.company"
				:ticket="ticket"
				:link-clickable="linkClickable"
				@click-gallery="handleGalleryClick"/>
		</div>
		<div v-if="canAdd" class="comment-add-pane">
			<TicketCommentInput
				ref="commentInput"
				@change="handleComment"/>
			<div class="comment-add-pane-actions">
				<div v-if="canResolveTicket" class="resolve-pane">
					<a-checkbox v-model="isResolved" class="resolve-checkbox">
						{{$t('ticket_resolved.is_resolved')}}
					</a-checkbox>
					<div v-show="isResolved && ticket.problemType === 'consult'">
						<div class="resolve-detail-row">
							<label>{{$t('ticket_resolved.reason.label')}}* :</label>
							<a-auto-complete
								v-model="resolvedData.reason"
								class="resolve-reason"
								:disabled="!isResolved"
								option-label-prop="value"
								allow-clear
								dropdown-class-name="resolve-reason-dropdown"
								:get-popup-container="getPopupContainer"
								@change="handleResolvedReasonChange" >
								<template slot="dataSource">
									<a-select-opt-group v-for="group in resolvedReasons" :key="group.title"
										class="resolve-reason-group">
										<span slot="label">
											{{group.title}}
										</span>
										<a-select-option v-for="item in group.items" :key="item" :value="item">
											{{item}}
										</a-select-option>
									</a-select-opt-group>
								</template>
							</a-auto-complete>
						</div>
						<div class="resolve-detail-row">
							<label>{{$t('ticket_resolved.result.label')}}* :</label>
							<a-radio-group
								v-model="resolvedData.resolved_result"
								:disabled="!isResolved"
								:options="resolvedResults"/>
						</div>
					</div>
				</div>

				<a-button type="primary" class="" :disabled="!hasCommentContent"
					@click="postComment">
					{{$t('ticket_comment.submit.label')}}
				</a-button>
			</div>
		</div>
		<LightBox
			v-if="galleryMedias.length > 0"
			ref="lightbox"
			:show-light-box="false"
			:show-caption="true"
			:media="galleryMedias"/>
	</div>
</template>

<script>
import {Checkbox,AutoComplete,Select,Radio} from "ant-design-vue"
import TicketCommentView from "./TicketCommentView.vue"
import TicketCommentSystemView from "./TicketCommentSystemView.vue"
import TicketCommentInput from "./TicketCommentInput.vue"
import LightBox from "vue-image-lightbox"
import {isStringEmpty as _isStringEmpty} from "@utils/stringUtil"
import {getEnumSelectOptions} from "@utils/formUtil"
import ApiError from '@utils/errors/ApiError'
import axios from "axios"
import PopupMixin from "@mixins/PopupMixin.vue"

export default {
	components : {
		"a-checkbox" : Checkbox, "a-auto-complete" : AutoComplete , "a-select-opt-group" : Select.OptGroup , "a-select-option" : Select.Option ,
		"a-radio-group" : Radio.Group,
		TicketCommentInput , TicketCommentView ,LightBox,
	},
	mixins : [PopupMixin] ,
	props : {
		ticket : {
			type : null,
			default : () => []
		} ,
		canAdd : {
			type : Boolean,
			default : false
		} ,
		hideSystemMessage : {
			type : Boolean,
			default : false
		} ,
		commentDataList : {
			type : Array ,
			default : () => []
		} ,
		linkClickable :{
			type : Boolean,
			default : true,
		} ,
	} ,
	data() {
		return {
			galleryMedias : [] ,
			newCommentData : {} ,
			commentLoading : false ,

			isResolved : false,
			resolvedData : {
				reason : undefined,
				resolved_result : undefined,
			} ,

		}
	} ,
	computed :{
		hasCommentContent() {
			let hasContent = !_isStringEmpty(this.newCommentData.detail_text) ||
				(this.newCommentData.files && this.newCommentData.files.length > 0) ||
				(this.newCommentData.gallery && this.newCommentData.gallery.length >0 )
			if (this.isResolved && this.ticket.problemType === 'consult') {
				hasContent &= (this.$notEmpty(this.resolvedData.reason) && this.$notEmpty(this.resolvedData.resolved_result))
			}
			return hasContent
		} ,
		canResolveTicket() {
			if (this.ticket.id > 0)
				return this.canAdd && this.$authorize('resolve','ticket') && !['cancel','close','resolved'].includes(this.ticket.status)

			else
				return false
		} ,
		resolvedResults() {
			return getEnumSelectOptions(this,'ticket.resolved_result',['unidentified','positive','negative'])
		} ,
		resolvedReasons() {
			return [
				{
					title : this.$t('ticket_resolved.reason.positive') ,
					items : [
						this.$t('ticket_resolved.reason.positive.1') ,
						this.$t('ticket_resolved.reason.positive.2') ,
						this.$t('ticket_resolved.reason.positive.3') ,
					] ,
					result : 'positive'
				} ,
				{
					title : this.$t('ticket_resolved.reason.negative') ,
					items : [
						this.$t('ticket_resolved.reason.negative.1') ,
						this.$t('ticket_resolved.reason.negative.2') ,
						this.$t('ticket_resolved.reason.negative.3') ,
					] ,
					result : 'negative'
				}
			]
		}
	} ,
	methods :{
		clear() {
			this.newCommentData = {}
			this.isResolved = false
			this.resolvedData.reason = undefined
			this.resolvedData.resolved_result = undefined
			if (this.$refs.commentInput) {
				this.$refs.commentInput.clear()
			}
		} ,
		commentComponent(commentData) {
			return commentData.comment.is_system ? TicketCommentSystemView : TicketCommentView
		} ,
		handleGalleryClick(data) {
			this.galleryMedias = [...data.event.mediaList]
			if (this.$refs.lightbox) {
				this.$refs.lightbox.showImage(data.event.index)
			}
		} ,
		handleComment(event) {
			this.newCommentData = event.data;
		} ,
		handleResolvedReasonChange() {
			if (this.$notEmpty(this.resolvedData.reason)) {
				const reason = this.resolvedData.reason.trim()
				const resolvedReason = this.resolvedReasons.find((r) => {
					return r.items.includes(reason)
				})
				if (resolvedReason) {
					this.resolvedData.resolved_result = resolvedReason.result
				}
			}
		} ,
		postComment() {
			if (!this.canAdd)
				return
			this.commentLoading = true
			const formData = {
				detail_rich : this.newCommentData.detail ,
				files : this.newCommentData.files ,
				galleries : this.newCommentData.gallery,
			}
			if (this.canResolveTicket && this.isResolved) {
				if (this.ticket.problemType === 'consult') {
					formData.resolved_data = {
						reason : this.resolvedData.reason,
						result : this.resolvedData.resolved_result,
					}
				} else {
					formData.resolved_data = {
						reason : this.$t('ticket_resolved.general.reason') ,
						result : 'unidentified'
					}
				}
			}

			axios.post('/api/tickets/'+this.ticket.id+'/comment',formData).then((response)=>{
				if (response.data.data.reload) {
					this.$router.go()
				} else {
					this.$emit('comment',response.data.data)
					this.$emit('ticket',response.data.data.ticket)
					this.clear()
				}

			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error))
			}).finally(()=>{
				this.commentLoading = false;
			})
		}
	}

}
</script>

<style lang="less" scoped>
.comment-list {
	display : flex;
	flex-flow: column;
}
.comment-add-pane {
	background : @white;
	padding : 24px 32px;
	margin-top : 24px;
	border : 1px solid @white;
	border-radius: @border-radius-base;
	box-shadow : @shadow-2;

	.comment-add-pane-actions {
		text-align: right;
		margin-top : 8px;

		> button {
			min-width : 150px;
		}
	}
}

.resolve-reason {
	width : 250px;
}

.resolve-checkbox {
	margin-bottom  : 16px;
	.mobile & {
		display : block;
		text-align : left;
		margin-top : 24px;
	}
}
.resolve-detail-row {
	margin-bottom : 16px;
	> label {
		color : @info-color;
	}
	.mobile & {
		padding-left : 16px;
		text-align : left;
		> label {
			display : block;
		}
	}
}

</style>
<style lang="less">
.resolve-reason-dropdown {
	.ant-select-dropdown-menu-vertical {
		padding : 0;
		max-height: none;
	}
}
.resolve-reason-group {
	.ant-select-dropdown-menu-item-group-title {
		background : @gray-4;
		color : @gray-7;
		font-size : 14px;
	}
}
</style>

<i18n locale="th" lang="yaml" >
part.field.sku.display : "SKU"
</i18n>
<template>
	<div class="mycard-select-card with-image">
		<div class="col-image">
			<ImageLoader
				:src="part.image_url"
				class="mycard-image"
				size="tiny"
				border-type="round">
				<a-icon type="deployment-unit" />
			</ImageLoader>
		</div>
		<div class="col-content">
			<div class="card-title">{{part.name}}</div>
			<div class="card-detail">
				<label>{{$t('part.field.sku.display')}} : </label> {{part.sku}}
			</div>
			<div v-if="$notEmpty(part.name_en)" class="card-detail text-muted">
				({{part.name_en}})
			</div>

		</div>
	</div>
</template>

<script>
import ImageLoader from "@components/common/ImageLoader.vue"
export default {
	components: {
		ImageLoader,
	},
	props : {
		part : {
			type : null,
			default : () => []
		} ,
	}
}
</script>

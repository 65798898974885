<i18n locale="th" lang="yaml" src="@i18n/service/claim.th.yaml">
</i18n>
<i18n locale="th" lang="yaml">
claim.action.ask_leave.title : "ยืนยันออกจากหน้าจอนี้"
claim.action.ask_leave.message : "คุณได้แก้ไขข้อมูลการเคลม และ ยังไม่ได้บันทึกเข้าระบบ, ยืนยันต้องการยกเลิกการแก้ไขนี้ ?"

claim.action.ask_reset : "ยกเลิกการแก้ไข"
claim.action.ask_reset.title : "@:claim.action.ask_reset"
claim.action.ask_reset.message : "คุณต้องการยกเลิกการแก้ไข และ นำข้อมูลเดิมในระบบกลับมา ?"

claim.action.update.no_change : "ไม่ได้มีการแก้ไขค่า"
claim.action.update.confirm.title : "ยืนยันการแก้ไขค่า"
claim.action.update.confirm.message : "คุณต้องการบันทึกการแก้ไขข้อมูลการเคลมของใบซ่อมนี้ ?"
claim.action.update.confirm.success : "ทำการบันทึกข้อมูลการเคลมของงานซ่อม {name} เรียบร้อย"
</i18n>

<template>
	<div v-show="!loading" class="page-padding">
		<template v-if="canUpdateClaim">
			<div class="page-action-right">
				<a-button @click="handleReset">
					{{$t('claim.action.ask_reset')}}
				</a-button>
				<a-button type="primary" class="btn-submit" @click="handleSave">
					{{$t('common.save')}}
				</a-button>
			</div>
			<ErrorMessagePane :error="saveError" />
			<br />
		</template>

		<ClaimStatusAndAction
			:claim-request="claimRequest"
			:service="service"
			:can-update-request-part="canUpdateRequestPart"
			:can-update-decision-part="canUpdateDecisionPart"
			:can-view-claim-full="canViewClaim"
			@cancel="handleClaimCancel"
			@changeStatus="handleClaimStatus"/>

		<ClaimPaymentRequest
			v-if="canViewPaymentPart"
			ref="paymentRequestRef"
			:service="service"
			:can-update="canUpdatePaymentPart"/>

		<ClaimRequestDetail
			ref="requestDetailRef"
			:service="service"
			:can-update-request="canUpdateRequestPart"
			:can-update-decision="canUpdateDecisionPart"
			:can-view-decision="canViewDecisionPart"
			:can-view-remark="canViewClaim"/>

		<ClaimCost
			v-if="canViewClaim"
			ref="costRef"
			:claim-request="claimRequest"
			:service-parts="serviceParts"
			:service="service" />

		<ClaimParts
			ref="claimPartsRef"
			:service="service"
			:can-update="canUpdateDecisionPart || canUpdateRequestPart"/>
		<ClaimTickets
			:service="service"
			:link-tickets="linkTickets"
			:can-update="canUpdateDecisionPart || canUpdateRequestPart"
			@changeLinkTickets="handleChangeTickets"/>

		<ClaimFiles
			ref="claimFilesRef"
			:service="service"
			:can-update="canUpdateDecisionPart || canUpdateRequestPart"/>

		<template v-if="canUpdateClaim">
			<div class="page-action-right">
				<a-button @click="handleReset">
					{{$t('claim.action.ask_reset')}}
				</a-button>
				<a-button type="primary" class="btn-submit" @click="handleSave">
					{{$t('common.save')}}
				</a-button>
			</div>
		</template>

	</div>
</template>

<script>
import PageMixin from "@mixins/PageMixin.vue"
import ServiceObjectMixin from "@/src/mixins/service/ServiceObjectMixin.vue"
import ClaimStatusAndAction from "@components/service/claim/ClaimStatusAndAction.vue"
import ClaimPaymentRequest from "@components/service/claim/ClaimPaymentRequest.vue"
import ClaimRequestDetail from "@components/service/claim/ClaimRequestDetail.vue"
import ClaimCost from "@components/service/claim/ClaimCost.vue"
import ClaimParts from "@components/service/claim/ClaimParts.vue"
import ClaimFiles from "@components/service/claim/ClaimFiles.vue"
import ClaimTickets from "@components/service/claim/ClaimTickets.vue"
import FormError from "@utils/errors/FormError"
import ErrorMessagePane from "@components/common/ErrorMessagePane.vue"
import {scrollTopContent} from "@utils/formUtil"
import _isEqual from "lodash/isEqual"
import axios from "axios"

export default {
	components : {
		ErrorMessagePane,
		ClaimStatusAndAction, ClaimPaymentRequest , ClaimRequestDetail,
		ClaimCost, ClaimParts, ClaimFiles, ClaimTickets,
	} ,
	mixins : [PageMixin,ServiceObjectMixin] ,
	props : {
		service : {
			type : null,
			default : () => []
		} ,
		otherDetails : {
			type : null,
			default : () => []
		} ,
		linkTickets : {
			type : null,
			default : () => []
		}
	} ,
	data() {
		return {
			loading : false,
			claimRequest : {} ,
			claimFiles : [] ,
			serviceParts : {},

			ignorePreventExit : false,
			oldFormData : {} ,
			saveError : undefined,
		}
	} ,
	computed : {
		canViewClaim() {
			return this.canServiceClaim &&
				this.$authorize('read','claim') &&
				'claim_request' in this.otherDetails
		} ,
		canUpdateRequestPart() {
			const status = this.claimRequest.status
			return ['draft','draft_appeal'].includes(status) &&
				this.$authorize('create','claim',{companyId : this.service.company_id})
		} ,
		canViewDecisionPart() {
			const status = this.claimRequest.status
			return ['approved','rejected'].includes(status) || this.canUpdateDecisionPart
		} ,
		canUpdateDecisionPart() {
			const status = this.claimRequest.status
			return ['request','appeal'].includes(status) && this.$authorize('decision','claim')
		} ,
		canViewPaymentPart() {
			return this.claimRequest.status == 'approved' && this.canViewClaim
		} ,
		canUpdatePaymentPart() {
			return this.claimRequest.status == 'approved' &&
				this.claimRequest.payment_request != 'complete' &&
				this.$authorize('payment','claim',{companyId : this.service.company_id})
		} ,
		canUpdateClaim() {
			return this.canUpdateRequestPart || this.canUpdateDecisionPart || this.canUpdatePaymentPart
		}
	} ,
	watch : {
		$route(newVal) {
			if (!this.claimRequest || newVal.params.id != this.claimRequest.id) {
				this.fetchData()
			}
		} ,
	} ,
	mounted() {
		this.fetchData();
	} ,
	beforeMount() {
		window.addEventListener("beforeunload",this.preventExit)
	} ,
	beforeDestroy() {
		window.removeEventListener("beforeunload",this.preventExit)
	} ,
	methods : {
		fetchData() {
			const serviceId = this.$route.params.id
			if (!serviceId) {
				return
			}
			this.ignorePreventExit = true
			if (!this.canViewClaim) {
				return this.$open({name : 'service/view',params : {id : serviceId}})
			}
			this.loading = true
			axios.get("/api/services/"+serviceId+"/claim-request").then((response) => {
				this.claimRequest = response.data.data.claim_request
				this.claimFiles = response.data.data.claim_files
				this.serviceParts = response.data.data.service_parts
				this.initClaimRequest()
			}).catch((error) => {
				this.fetchError(error)
			}).finally(() => {
				this.loading = false
				this.ignorePreventExit = false
			})
		} ,
		initClaimRequest() {
			this.saveError = undefined
			this.$nextTick(() => {
				this.$refs.requestDetailRef.setData(this.claimRequest)
				this.$refs.claimPartsRef.setData(this.claimRequest,this.serviceParts)
				this.$refs.claimFilesRef.setData(this.claimRequest,this.claimFiles)
				if (this.$refs.paymentRequestRef) {
					this.$refs.paymentRequestRef.setData(this.claimRequest)
				}
				this.oldFormData = this.dumpFormData()
			})
		} ,

		dumpFormData() {
			let requestDetailData = {}
			let paymentRequestData = {}
			let costData = {}
			if (this.canUpdatePaymentPart && this.$refs.paymentRequestRef) {
				paymentRequestData = this.$refs.paymentRequestRef.getData()
			}
			if (this.canUpdateRequestPart || this.canUpdateDecisionPart) {
				requestDetailData = this.$refs.requestDetailRef.getData()
			}
			if (this.$refs.costRef) {
				costData = this.$refs.costRef.getData()
			}

			const claimRequest = {
				id : this.claimRequest.id ,
				...requestDetailData ,
				...paymentRequestData,
				...costData,
			}

			const formData = {
				claim_request : claimRequest,
				claim_files: []
			}
			if (this.canUpdateRequestPart || this.canUpdateDecisionPart) {
				formData.claim_parts = this.$refs.claimPartsRef.getData()
				formData.claim_files.push({upload_type: 'claim', files: this.$refs.claimFilesRef.getData()})

			}
			return formData
		} ,
		handleReset() {
			if (!this.canUpdateClaim)
				return
			const formData = this.dumpFormData()
			if (!this.isDataChange(formData)) {
				scrollTopContent()
				return
			}
			this.$confirm({
				title : this.$t('claim.action.ask_reset.title') ,
				content : this.$t('claim.action.ask_reset.message') ,
				okText : this.$t('common.confirm') ,
				maskClosable : true,
				onOk: ()=> {
					this.initClaimRequest()
					scrollTopContent()
				} ,
			})
		} ,
		validatePaymentData(formData) {
			this.saveError = undefined
			const formError = new FormError(this)
			const checkComplete = formData.claim_request.payment_request == 'complete'
			const checkRequest = ['request','complete'].includes(formData.claim_request.payment_request)

			if (checkRequest && !this.$notEmpty(formData.claim_request.payment_requested_date)) {
				formError.addGeneralMessage(this.$t("validate.required",{field : this.$t('claim.field.payment_requested_date')}))
			}
			if (checkComplete && !this.$notEmpty(formData.claim_request.payment_completed_date)) {
				formError.addGeneralMessage(this.$t("validate.required",{field : this.$t('claim.field.payment_completed_date')}))
			}

			if (formError.hasErrors()) {
				this.saveError = formError
				this.$nextTick(()=> {
					scrollTopContent()
				})
				return false
			} else {
				return true
			}
		} ,
		validateFormData(formData,action) {
			this.saveError = undefined
			const formError = new FormError(this)
			switch (action) {
				case 'request' :
				case 'appeal' :
					if (!this.$notEmpty(formData.claim_request.detail)) {
						formError.addGeneralMessage(this.$t("validate.required",{field : this.$t('claim.field.detail')}))
					}
					break;
				case 'approved' :
				case 'rejected' :
					if (!this.$notEmpty(formData.claim_request.reason)) {
						formError.addGeneralMessage(this.$t("validate.required",{field : this.$t('claim.field.reason')}))
					}
					if (!this.$notEmpty(formData.claim_request.analysis)) {
						formError.addGeneralMessage(this.$t("validate.required",{field : this.$t('claim.field.analysis')}))
					}
					break;
			}

			if (formError.hasErrors()) {
				this.saveError = formError
				this.$nextTick(()=> {
					scrollTopContent()
				})
				return false
			} else {
				return true
			}
		} ,
		handleClaimStatus(payload) {
			// validate form
			const formData = this.dumpFormData()
			if (!this.validateFormData(formData,payload.nextStatus)) {
				return
			}
			formData.next_status = payload.nextStatus
			this.showPageLoading(true)
			axios.post("/api/services/"+this.service.id+"/claim-request/update-status",formData).then((response)=>{
				this.ignorePreventExit = true
				this.$message.success(payload.successMessage)
				this.$router.go()
			}).catch((error) => {
				this.saveError = error
			}).finally(()=>{
				this.hidePageLoading()
			})
		} ,
		handleClaimCancel(payload) {
			this.showPageLoading(true)
			axios.delete("/api/services/"+this.service.id+"/claim-request").then((response) => {
				this.ignorePreventExit = true
				this.$message.success(payload.successMessage)
				this.$open({name : 'service/view' , params : {id : this.service.id}},'_self',true)
			}).catch((error) => {
				this.saveError = error
			}).finally(()=>{
				this.hidePageLoading()
			})
		} ,
		handleSave() {
			if (!this.canUpdateClaim)
				return
			const formData = this.dumpFormData()
			if (!this.isDataChange(formData)) {
				this.$message.info(this.$t('claim.action.update.no_change'))
				return
			}
			if (this.canUpdatePaymentPart && !this.validatePaymentData(formData)) {
				return
			}
			this.$confirm({
				title : this.$t('claim.action.update.confirm.title') ,
				content : this.$t('claim.action.update.confirm.message') ,
				okText : this.$t('common.confirm') ,
				maskClosable : true,
				onOk: ()=> {
					this.showPageLoading(true)

					axios.put("/api/services/"+this.service.id+"/claim-request",formData).then((response)=>{
						this.ignorePreventExit = true
						this.$message.success(this.$t('claim.action.update.confirm.success',{name:this.service.service_no}))
						this.$router.go()
					}).catch((error) => {
						this.saveError = error
					}).finally(()=>{
						this.hidePageLoading()
					})
				} ,
			})
		} ,
		preventExit(event) {
			if (this.checkPreventExit()) {
				event.preventDefault()
				event.returnValue = ""
			}
		},
		handleChangeTickets(payload) {
			this.$emit("changeLinkTickets",payload)
		} ,
		isDataChange(formData) {
			const change = !_isEqual(this.oldFormData,formData)
			return change
		} ,
		checkPreventExit(formData=null) {
			if (!this.canUpdateClaim || this.ignorePreventExit)
				return false
			if(!formData) {
				formData = this.dumpFormData()
			}
			return this.isDataChange(formData)
		}
	} ,
	beforeRouteLeave(routeTo,routeFrom,next) {
		const formData = this.dumpFormData()
		if (this.checkPreventExit(formData)) {
			this.$confirm({
				title : this.$t('claim.action.ask_leave.title') ,
				content : this.$t('claim.action.ask_leave.message') ,
				okText : this.$t('common.confirm') ,
				maskClosable : true,
				onOk: ()=> {
					next()
				} ,
			})
		} else {
			next()
		}
	}
}
</script>

<i18n locale="th" lang="yaml" >
link_ticket_modal.title : "Ticket ที่เกี่ยวข้อง"
link_ticket_modal.ok.label : "ผูก Ticket"
link_ticket_modal.unselect.label : "ยกเลิก"
link_ticket_modal.select.label : "เลือก"
link_ticket_modal.new_ticket.label : "เปิดใบงานใหม่"

link_ticket_modal.description : "แสดงผลเฉพาะ Ticket ที่รายงานก่อนรับเครื่อง 2 เดือน"
link_ticket_modal.description.date : "(ตั้งแต่วันที่ {date})"

action.cancel_bind.label : "ยกเลิก"
action.bind.label : "ผูกใบงาน"

</i18n>

<template>
	<a-modal
		:visible="visible"
		:title="$t('link_ticket_modal.title')"
		:width="500"
		:mask-closable="!loading"
		@cancel="handleCancel">
		<div slot="footer" class="ticket-description">
			{{$t('link_ticket_modal.description')}}
			<span v-if="$notEmpty(ticketStartDate)">
				{{$t('link_ticket_modal.description.date',{ date: $dayjs(ticketStartDate).format("LL") })}}
			</span>
		</div>
		<div class="link-ticket-modal-content">
			<div v-if="canOpenNew" class="page-action-right">
				<a-button
					type="primary"
					icon="plus"
					@click="clickNewTicket">
					{{$t('link_ticket_modal.new_ticket.label')}}
				</a-button>
			</div>
			<ErrorMessagePane :error="processError" />

			<a-list item-layout="vertical" :data-source="tickets" :pagination="pagination"
				:loading="loading"
				class="ticket-list">

				<TicketSelectableCard
					slot="renderItem" key="ticket.id" slot-scope="ticket"
					:ticket="ticket"
					size="small"
					:bordered="true"
					link-target="_blank"
					:actived="ticket.actived"
					:disabled="ticket.status == 'cancel'"
					:clickable="false">
					<div slot="action">
						<span v-if="ticket.actived">
							<a-icon type="link" class="link-status-icon text-success" />
							<a-button size="small"
								class="btn-danger-outline"
								@click="clickCancel(ticket)">
								{{$t('action.cancel_bind.label')}}
							</a-button>
						</span>
						<span v-else>
							<a-button size="small" @click="clickBind(ticket)">
								{{$t('action.bind.label')}}
							</a-button>
						</span>
					</div>
				</TicketSelectableCard>
			</a-list>

		</div>
	</a-modal>
</template>

<script>
import {List} from "ant-design-vue"
import TicketSelectableCard from "@components/ticket/TicketSelectableCard.vue"
import axios from "axios"
import ErrorMessagePane from "@components/common/ErrorMessagePane.vue"

export default {
	components : {
		"a-list" : List,
		TicketSelectableCard , ErrorMessagePane
	} ,
	props : {
		service : {
			type : null,
			default : () => []
		} ,
		canOpenNew : {
			type : null,
			default : false,
		}
	} ,
	data() {
		return {
			visible : false,
			loading : false,
			processError : undefined,
			tickets : [] ,
			ticketStartDate : undefined,
			pagination : {
				pageSize : 5 ,
				current : 1,
				hideOnSinglePage : true,
				onChange : (page) => {
					this.pagination.current = page
				}
			} ,
		}
	} ,

	methods : {
		openModal() {
			if (!(this.service.id && this.service.id > 0))
				return
			this.visible=true
			this.fetchLinkableTickets()
		} ,
		fetchLinkableTickets() {
			this.loading = true
			this.processError = undefined
			this.ticketStartDate = undefined
			axios.get("/api/services/linkable-tickets/"+this.service.id).then((response)=>{
				this.processTickets(response.data.data)
			}).catch((error) => {
				this.processError = error
			}).finally(() => {
			 	this.loading = false
			})
		} ,
		processTickets(responseData) {
			this.ticketStartDate = responseData.ticketStartDate
			this.tickets = []
			const currentTicketIdList = responseData.linkTickets.map((linkTicket) => linkTicket.ticket_id)
			this.pagination.current = 1
			for(const ticket of responseData.tickets) {
				ticket.actived = currentTicketIdList.includes(ticket.id)
				if (!ticket.actived && ticket.status == 'cancel') {
					continue
				}
				this.tickets.push(ticket)
			}
		} ,
		closeModal() {
			this.visible = false
			this.tickets = []
			this.pagination.current = 1
		} ,
		handleCancel() {
			if (this.loading)
				return
			this.closeModal()
		} ,
		clickNewTicket() {
			this.$open({name : 'ticket/create_service',params : {id : this.service.id,from : this.$route.fullPath}})
		} ,
		clickBind(ticket) {
			if (!ticket.id ||ticket.actived)
				return
			this.loading = true
			this.processError = undefined
			this.ticketStartDate = undefined
			axios.post("/api/services/bind-ticket/"+this.service.id,{ticket_id : ticket.id}).then((response)=>{
				return axios.get("/api/services/linkable-tickets/"+this.service.id)
			}).then((response)=>{
				this.processTickets(response.data.data)
				this.emitChange(response.data.data.linkTickets)
			}).catch((error)=> {
				this.processError = error
			}).finally(()=>{
				this.loading = false
			})
		} ,
		clickCancel(ticket) {
			if (!ticket.id || !ticket.actived)
				return
			this.loading = true
			this.processError = undefined
			this.ticketStartDate = undefined
			axios.post("/api/services/bind-cancel-ticket/"+this.service.id,{ticket_id : ticket.id}).then((response)=>{
				return axios.get("/api/services/linkable-tickets/"+this.service.id)
			}).then((response)=>{
				this.processTickets(response.data.data)
				this.emitChange(response.data.data.linkTickets)
			}).catch((error)=> {
				this.processError = error
			}).finally(()=>{
				this.loading = false
			})
		} ,
		emitChange(linkTickets) {
			this.$emit('change',{link_tickets : linkTickets})
		}
	}
}
</script>

<style lang="less" scoped>
.link-status-icon {
	font-size : 1.5em;
	margin-bottom : 8px;
	display : block;
}
.ticket-list {
	min-height : 300px;
}
.ticket-description {
	font-size : 0.9em;
}

</style>

import { render, staticRenderFns } from "./MyTextAreaView.vue?vue&type=template&id=7f95b13b&scoped=true"
import script from "./MyTextAreaView.vue?vue&type=script&lang=js"
export * from "./MyTextAreaView.vue?vue&type=script&lang=js"
import style0 from "./MyTextAreaView.vue?vue&type=style&index=0&id=7f95b13b&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f95b13b",
  null
  
)

export default component.exports
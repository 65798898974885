<template>
	<quill-editor
		ref="quillEditor"
		class="richtext-editor"
		:content="value"
		:options="EDITOR_OPTIONS"
		@change="onEditorChange($event)"/>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import {quillEditor} from "vue-quill-editor"
import _debounce from "lodash/debounce"
export default {
	components : {
		"quill-editor" : quillEditor,
	} ,
	model : {
		prop : 'value',
		event : 'change',
	} ,
	props : {
		value : {
			type : String,
			default : ''
		} ,
		placeholder : {
			type : String,
			default : ''
		}
	} ,
	computed : {
		EDITOR_OPTIONS(){
			return {
				theme : 'snow' ,
				placeholder : this.placeholder,
				modules : {
					toolbar : [
						['bold', 'italic', 'underline', 'strike'],
						['blockquote', 'code-block'],
						[{ 'list': 'ordered'}, { 'list': 'bullet' }],
						[{ 'indent': '-1'}, { 'indent': '+1' }],
						[{ 'size': ['small', false, 'large', 'huge'] }],
						[{ 'color': [] }, { 'background': [] }],
						[{ 'align': [] }],
						['link'] ,
						['clean']
					]
				}
			}
		}
	},
	created() {
		this.onEditorChange = _debounce(this._onEditorChange,200)
	} ,
	methods : {
		setContent(content) {
			// Workaround for auto focus when set initial content (Window will scroll to richtext editor which isn't unexpected behavior)
			const quill = this.$refs.quillEditor.quill
			if (this.$notEmpty(content)) {
				// Workaround for removing trail new line of delta
				const convert = content + '<p><br></p>'
				const after = quill.clipboard.convert(convert)
				quill.setContents(after)
			}
			this.$emit('change',content)
		} ,
		_onEditorChange({ quill, html, text }) {
			this.$emit('change',html)
			this.$emit('changeText',text.trim())
		} ,
	}
}
</script>

<style lang="less">
.richtext-editor {
	max-width : 1024px;
	.ql-editor {
		min-height : 150px;
		font-size : 13px;

		.ql-size-small {
			font-size : 0.85em;
		}
		.ql-size-large {
			font-size : 1.25em;
		}
		.ql-size-huge {
			font-size : 1.75em;
		}
		ol,ul {
			padding-left : 0.5em;
		}
		&.ql-blank::before {
			color : @gray-6;
		}
	}
}
</style>

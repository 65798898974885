<i18n locale="th" lang="yaml" >
page.title : "Ticket : {name}"
</i18n>

<template>
	<a-modal
		:visible="visible"
		:mask-closable="true"
		:footer="null"
		width="65%"
		@cancel="closeModal">
		<div class="page page-padding">
			<a-spin :spinning="isEmptyTicket">
				<my-page-header
					:title="$t('page.title',{ name: ticket.ticket_no })"/>
				<TicketDetail
					:ticket="ticket"
					:link-services="linkServices"
					:link-clickable="linkClickable"/>
				<div class="mylayout-right-left responsive reverse ticket-container">
					<div class="mylayout-left">
						<TicketCommentList
							:hide-system-message="true"
							:ticket="ticket"
							:comment-data-list="commentDataList"
							:link-clickable="linkClickable"/>
					</div>
				</div>
			</a-spin>
		</div>
	</a-modal>
</template>

<script>
import TicketCommentList from "@components/ticket/TicketCommentList.vue"
import TicketDetail from '@components/ticket/TicketDetail.vue'
import isEmpty from "lodash/isEmpty"

export default {
	components : {
		TicketCommentList ,
		TicketDetail,
	} ,
	props : {
		ticket : {
			type: Object,
			default: () => {}
		} ,
		linkServices: {
			type: Array,
			default: () => []
		} ,
		commentDataList: {
			type: Array,
			default: () => []
		} ,
		linkClickable :{
			type : Boolean,
			default : true,
		} ,
	} ,
	data() {
		return {
			visible : false,
		}
	} ,
	computed: {
		isEmptyTicket() {
			return isEmpty(this.ticket)
		}
	} ,
	methods : {
		openModal() {
			this.visible = true
		} ,
		closeModal() {
			this.visible = false
		} ,
	}
}
</script>

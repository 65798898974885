<i18n locale="th" lang="yaml" src="@i18n/service/claim.th.yaml">
</i18n>
<i18n locale="th" lang="yaml" >
claim.actions.download : "Download ใบเคลม"
claim.actions.request.label : "ยื่นขออนุมัติ"
claim.actions.request.tooltip : "ทำการยื่นใบเคลม เพื่อให้ทาง HGR ตรวจสอบ"
claim.actions.request.confirm.title : "ยืนยันการยื่นขออนุมัติ"
claim.actions.request.confirm.message : "คุณต้องการบันทึกข้อมูล และ ทำการยื่นใบเคลมนี้ (ข้อมูลจะไม่สามารถแก้ไขได้จนกว่าจะรู้ผลอนุมัติ) ?"
claim.actions.request.confirm.success : "ได้ทำการยื่นใบเคลมให้ทาง HGR ตรวจสอบเรียบร้อย กรุณารอผลการตรวจสอบ"

claim.actions.cancel.label : "ยกเลิก"
claim.actions.cancel.tooltip : "ทำการยกเลิกใบเคลม"
claim.actions.cancel.confirm.title : "ยกเลิกการขอเคลม"
claim.actions.cancel.confirm.message : "คุณต้องการยกเลิกการขอเคลมนี้ (ข้อมูลที่กรอกไปแล้วจะไม่สามารถเรียกคืนได้) ?"
claim.actions.cancel.confirm.success : "ทำการยกเลิกใบเคลมเรียบร้อย"

claim.actions.approved.label : "อนุมัติ"
claim.actions.approved.tooltip : "ทำการอนุมัติใบเคลม"
claim.actions.approved.confirm.title : "อนุมัติใบเคลม"
claim.actions.approved.confirm.message : "คุณต้องการอนุมัติใบเคลมนี้ ?"
claim.actions.approved.confirm.success : "ทำการอนุมัติใบเคลมเรียบร้อย"

claim.actions.rejected.label : "ไม่อนุมัติ"
claim.actions.rejected.tooltip : "ไม่อนุมัติใบเคลม"
claim.actions.rejected.confirm.title : "ไม่อนุมัติใบเคลม"
claim.actions.rejected.confirm.message : "ใบเคลมนี้ไม่ได้อนุมัติจากทาง HGR ?"
claim.actions.rejected.confirm.success : "ทำการปฏิเสธใบเคลมนี้เรียบร้อย"

claim.actions.draft_appeal.label : "ขอทำเรื่องใหม่"
claim.actions.draft_appeal.tooltip : "ระบุรายละเอียดใบเคลมใหม่ หลังทราบผลแล้ว"
claim.actions.draft_appeal.confirm.title : "ทำเรื่องใหม่"
claim.actions.draft_appeal.confirm.message : "คุณต้องการแก้ไขใบเคลมและลองยื่นใหม่ ?"
claim.actions.draft_appeal.confirm.success : "ใบเคลมถูกปรับมาเพื่อแก้ไขข้อมูลและยื่นใหม่"

claim.actions.appeal.label : "ยื่นใบเคลมใหม่"
claim.actions.appeal.tooltip : "ทำการแก้ไขใบเคลมใหม่ พร้อมยื่นให้ทาง HGR ตรวจสอบใหม่"
claim.actions.appeal.confirm.title : "ยื่นใบเคลมใหม่"
claim.actions.appeal.confirm.message : "คุณต้องการยื่นใบเคลมใหม่ ?"
claim.actions.appeal.confirm.success : "ใบเคลมถูกส่งให้ทาง HGR ตรวจสอบใหม่อีกรอบ"
</i18n>

<template>
		<div class="claim-actions">
			<div class="claim-status-detail">
				<div v-if="$notEmpty(claimRequest.claim_no)" class="claim-no">
					{{$t('claim.field.claim_no.display',{ value: claimRequest.claim_no })}}
				</div>
				<div class="title">
					{{$tenum('claim.status',claimRequest.status)}}
				</div>
				<div class="description">
					{{$tenum('claim.status',claimRequest.status + '.description')}}
				</div>
			</div>
			<div v-if="showClaimInfo" class="claim-info">
				<div v-if="$notEmpty(claimRequest.requestedByData)" class="claim-info-col">
					<label>{{$t('claim.field.requested_by')}} : </label>
					<div class="claim-info-val">
						<ImageName :name="claimRequest.requestedByData.user.username"
							:src="claimRequest.requestedByData.user.image_url">
							<a-icon type="user" />
						</ImageName>
						<span class="info-time image-text-tiny">
							( {{$t('common.since')}}
							{{claimRequest.requested_datetime | date}}
							)
						</span>
					</div>
				</div>

				<div v-if="$notEmpty(claimRequest.decisionByData)" class="claim-info-col">
					<label>{{$t('claim.field.decision_by')}} : </label>
					<div class="claim-info-val">
						<ImageName :name="claimRequest.decisionByData.user.username"
							:src="claimRequest.decisionByData.user.image_url">
							<a-icon type="user" />
						</ImageName>
						<span class="info-time image-text-tiny">
							( {{$t('common.since')}}
							{{claimRequest.decision_datetime | date}}
							)
						</span>
					</div>
				</div>
			</div>
			<div v-if="$notEmpty(actionList) || canClaimDownload" class="claim-action-pane">
				<div v-for="action of actionList" :key="action.action" class="claim-action">
					<a-button :class="'claim-action-btn ' + action.css"
						:type="action.button_type"
						:title="$t('claim.actions.' + action.action + '.tooltip')"
						@click="handleClaimAction(action)">
						{{$t('claim.actions.' + action.action + '.label')}}
					</a-button>
				</div>
				<div v-if="canClaimDownload" class="claim-action claim-download">
					<a-button class="claim-action-btn" @click="handleClaimDownload">
						<a-icon type="download" /> {{$t('claim.actions.download')}}
					</a-button>
				</div>
			</div>
		</div>
</template>

<script>
import PageMixin from "@mixins/PageMixin.vue"
import ImageName from "@components/common/ImageName.vue"
import {TIMEOUT_FILE_DOWNLOAD} from "@utils/axiosUtil"
import fileDownload from 'js-file-download'
import ApiError from "@utils/errors/ApiError"
import axios from "axios"

export default {
	components : {
		ImageName,
	} ,
	mixins : [PageMixin] ,
	props : {
		service : {
			type : null,
			default : () => []
		} ,
		claimRequest : {
			type : null,
			default : () => []
		} ,
		canUpdateRequestPart : {
			type : Boolean ,
			default : false,
		} ,
		canUpdateDecisionPart : {
			type : Boolean,
			default : false,
		} ,
		canViewClaimFull : {
			type : Boolean,
			default : false,
		}
	} ,
	computed : {
		actionList() {
			const actions = []
			switch(this.claimRequest.status) {
				case 'draft' :
					if (this.canUpdateRequestPart) {
						actions.push({'action' : 'request','button_type' : 'primary'})
						actions.push({'action' : 'cancel'})
					}
					break;
				case 'request' :
				case 'appeal' :
					if (this.canUpdateDecisionPart) {
						actions.push({'action' : 'approved','button_type' : 'success'})
						actions.push({'action' : 'rejected','button_type' : 'danger'})
					}
					break;
				case 'approved' :
				case 'rejected' :
					if (this.$authorize('create','claim',{companyId : this.service.company_id}) && this.claimRequest.payment_request == 'none')	 {
						actions.push({'action' : 'draft_appeal'})
					}
					break;
				case 'draft_appeal' :
					if (this.canUpdateRequestPart) {
						actions.push({'action' : 'appeal'})
					}
					break
			}
			return actions
		} ,
		showClaimInfo() {
			return this.$notEmpty(this.claimRequest.requestedByData) ||
				this.$notEmpty(this.claimRequest.decistionByData)
		} ,
		canClaimDownload() {
			return this.canViewClaimFull && this.claimRequest.status =='approved'
		} ,
	} ,
	methods : {
		handleClaimDownload() {
			if (!this.canClaimDownload)
				return
			this.showPageLoading(true)
			axios.get("/api/services/"+this.service.id+"/claim-request/download",{
				responseType: 'blob' ,
				timeout : TIMEOUT_FILE_DOWNLOAD
			}).then((response) => {
				fileDownload(response.data,this.claimRequest.claim_no+".pdf");
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error));
			}).finally(() => {
				this.hidePageLoading()
			})
		} ,
		handleClaimAction(action) {
			const prefix = 'claim.actions.'+action.action+'.confirm'
			this.$confirm({
				title : this.$t(prefix+'.title') ,
				content : this.$t(prefix+'.message') ,
				okText : this.$t('common.confirm') ,
				maskClosable : true,
				onOk: ()=> {
					if (action.action == 'cancel') {
						this.$emit('cancel',{successMessage : this.$t(prefix+'.success')})
					} else {
						this.$emit('changeStatus',{
							nextStatus : action.action,
							successMessage : this.$t(prefix+'.success')
						})
					}
				}
			})
		} ,
	}
}
</script>

<style lang="less" scoped>
.claim-actions {
	max-width : 1200px;
	margin : 0 auto 48px;
	padding : 24px 36px;
	border : 1px solid @primary-color;
	border-radius : 4px;

	.mobile & {
		padding : 12px 16px;
	}
}

.claim-action-pane {
	padding-top : 16px;
	border-top : 1px dashed @border-color-base;
	.clearfix();
}

.claim-action {
	display : inline-block;
	margin-right : 4px;
	margin-bottom : 2px;
	&:last-child {
		margin-right : 0;
	}
	&.claim-download {
		float : right;
		margin-left : 4px;
	}
	.claim-action-btn {
		display : inline-block;
		min-width : 120px;
	}
}

.claim-status-detail {
	font-family : @font-family-title;
	text-align : center;
	margin-bottom : 16px;
	.claim-no {
		font-size : 1.1em;
		margin-bottom : 12px;
		text-decoration: underline;
		text-align : right;
	}
	.title {
		font-size : 2em;
		color : @primary-color;
	}
	.description {
		margin-top : 8px;
	}
}

.claim-info {
	padding-top : 16px;
	padding-bottom : 16px;
	display : flex;
	flex-wrap: nowrap;
	.claim-info-col {
		flex-grow : 1;
		min-width: 0;
		text-align: center;
		label {
			display : inline-block;
			color : @primary-color;
			margin-right : 4px;
		}
		.claim-info-val {
			display : inline-block;
		}
	}
}
</style>

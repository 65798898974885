<i18n locale="th" lang="yaml" >
claim.claim_files : "ไฟล์ประกอบการยื่นเคลม"
claim.claim_files.description : "ถ้ามีการเปิด Ticket เพื่อปรึกษาการเคลมแล้ว และ  File ประกอบเหมือนกัน ก็ไม่จำเป็นต้อง Upload File ใหม่ เพียงแค่คุณใส่ข้อมูล Ticket ที่เกี่ยวข้องแทน"
claim.claim_files.files.label : "รูป/วีดีโอ ประกอบการเคลม"
claim.claim_files.others.label : "ไฟล์ประกอบอื่น ๆ เช่น Log Files"
</i18n>

<template>
	<div class="claim-files-container">
		<div class="mytab-section-title">
			{{$t('claim.claim_files')}}
			<div class="mytab-section-description">
				{{$t('claim.claim_files.description')}}
			</div>
		</div>
		<div class="claim-subsection">
			<ServiceFilesPanel
				ref="filesRef"
				:service="service"
				:label="$t('claim.claim_files.files.label')"
				:others-label="$t('claim.claim_files.others.label')"
				error-key="claim_files"
				file-type-field="file_type"
				:can-update="canUpdate" />
		</div>
	</div>
</template>

<script>
import ServiceFilesPanel from "@components/service/ServiceFilesPanel.vue"

export default {
	components : {
		ServiceFilesPanel,
	} ,
	props : {
		service : {
			type : null,
			default : () => []
		} ,
		canUpdate : {
			type : Boolean,
			default : false
		}
	} ,
	methods : {
		setData(claimRequest,claimFiles) {
			this.$refs.filesRef.setData(claimFiles)
		} ,
		getData() {
			return this.$refs.filesRef.getData()
		}
	}
}
</script>

<style lang="less" scoped>
@import 'claim.less';
</style>

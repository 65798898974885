<i18n locale="th" lang="yaml" src="@i18n/service/claim.th.yaml">
</i18n>
<i18n locale="th" lang="yaml" >
claim.cost : "ค่าใช้จ่ายเรียกเก็บ"
claim.cost.description : "ประเมินค่าใช้จ่ายที่ทางศูนย์จะเรียกเก็บ"
</i18n>

<template>
	<div class="claim-cost-container">
		<div class="mytab-section-title">
			{{ $t('claim.cost') }}
			<div class="mytab-section-description">
				{{ $t('claim.cost.description') }}
			</div>
		</div>

		<div class="claim-subsection">
			<div class="claim-row claim-row-sameline">
				<label>{{ $t('claim.field.part_fee') }}</label>
				<div class="claim-value">
					{{ partFee | price }}
				</div>
			</div>

			<div class="claim-row claim-row-sameline">
				<label>{{ $t('claim.field.technician_fee') }}</label>
				<div class="claim-value">
					{{ technicianFee | price }}
				</div>
			</div>

			<div class="claim-row claim-row-sameline">
				<label>{{ $t('claim.field.service_fee') }}</label>
				<div class="claim-value">
					{{ serviceFee | price }}
				</div>
			</div>

			<div class="claim-row cost-summary claim-row-sameline">
				<label>{{ $t('claim.field.total_claim_fee') }}</label>
				<div class="claim-value">
					{{ totalClaimFee | price }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { CLAIM_REQUEST_STATUS } from '@/src/utils/claimUtil'
import DronePartMixin from '@mixins/drone/DronePartMixin.vue'
import { getPartsByTypeService, calculateTechnicianFeeByTotal } from '@utils/serviceUtil'

export default {
	mixins: [DronePartMixin],
	props: {
		service: {
			type: null,
			default: {},
		},
		claimRequest: {
			type: null,
			default: {},
		},
		serviceParts: {
			type: null,
			default: {},
		},
	},
	data() {
		return {
			costValue: {
				total_claim_fee: null,
			},
		}
	},
	computed: {
		...mapState('drone', ['manHourPrice']),
		...mapGetters('drone',['getPartsInfo']) ,
		isUseDBValue() {
			return this.claimRequest.status === CLAIM_REQUEST_STATUS.APPROVED
		},
		repairParts() {
			return this.getPartsInfo(this.serviceParts.repair)
		},
		repairPartsExcludeServ() {
			return getPartsByTypeService(this.repairParts, false)
		},
		repairPartsIncludeServ() {
			return getPartsByTypeService(this.repairParts, true)
		},
		partFee() {
			const partFee = this.claimRequest.part_fee ? this.claimRequest.part_fee : 0
			if (this.isUseDBValue) return partFee

			return this.calculateFee(this.repairPartsExcludeServ)
		},
		technicianFee() {
			const technicianFee = this.claimRequest.technician_fee ? this.claimRequest.technician_fee : 0
			if (this.isUseDBValue) return technicianFee


			const totalTechnicianFeeAllPart = this.calculateTotalTechnicianFeeAllPart(this.repairPartsExcludeServ)
			const finalTechnicianFeeAllPart = this.calculateFinalTechnicianFeeAllPart(this.repairPartsExcludeServ)
			return totalTechnicianFeeAllPart - finalTechnicianFeeAllPart
		},
		serviceFee() {
			const serviceFee = this.claimRequest.service_fee ? this.claimRequest.service_fee : 0
			if (this.isUseDBValue) return serviceFee

			return this.calculateFee(this.repairPartsIncludeServ)
		},
		totalClaimFee() {
			const totalClaimFee = this.claimRequest.total_claim_fee ? this.claimRequest.total_claim_fee : 0
			if (this.isUseDBValue) return totalClaimFee

			return this.partFee + this.technicianFee + this.serviceFee
		},
	},
	methods: {
		calculateFee(items = []){
			const result = items.reduce((acc, i) => acc + i.priceAllClaimApproveUnit, 0)
			return result
		},
		calculateTotalTechnicianFeeAllPart(items = []) {
			const sumTotalTime = items.reduce((acc, i) => acc + i.totalTime, 0)
			return calculateTechnicianFeeByTotal(sumTotalTime, this.manHourPrice)
		},
		calculateFinalTechnicianFeeAllPart(items = []) {
			const sumFinalTime = items.reduce((acc, i) => acc + i.finalApprovedTime, 0)
			return calculateTechnicianFeeByTotal(sumFinalTime, this.manHourPrice)
		},
		getData() {
			return {
				part_fee: this.partFee,
				technician_fee: this.technicianFee,
				service_fee: this.serviceFee,
				total_claim_fee: this.totalClaimFee,
			}
		},
	},
}
</script>

<style lang="less" scoped>
@import 'claim.less';
.cost-summary {
	font-size: 1.1em;
	font-weight: 600;
}
</style>

<template>
	<div class="comment-box">
		<QuillViewer class="comment-content" :content="comment.detail_rich" />
		<div class="comment-info">
			<span>
				{{$t('common.by')}}
			</span>
			<my-router-link
				name="user/view" :param-value="user.id" :new-window="true">
				{{user.username}}
			</my-router-link>
			<span>
				{{$t('common.from')}}
			</span>
			<my-router-link name="company/view" :param-value="company.id" auth-action="read" auth-module="company"
			 :new-window="true">
			 {{company.name}}
			</my-router-link>

			<DayFromNowText
					class="comment-info-time"
					:prefix="$t('common.since')"
					:value="comment.created_datetime" />

		</div>
	</div>
</template>

<script>
import QuillViewer from "@components/common/QuillViewer.vue"
import DayFromNowText from "@components/common/DayFromNowText.vue"
export default {
	components : {
		QuillViewer , DayFromNowText
	} ,
	props : {
		ticket : {
			type : null,
			default : () => []
		} ,
		comment : {
			type : null,
			default : ()=>[]
		} ,
		user : {
			type : null,
			default : ()=>[]
		} ,
		company : {
			type : null,
			default : ()=>[]
		}
	} ,

}
</script>

<style lang="less" scoped>
.comment-box {
	border-radius : @border-radius-base;
	padding : 12px 16px;
	margin-bottom : 12px;
	max-width : 750px;
	background-color : @yellow-1;
	align-self: flex-end;
	font-size : 0.95em;
}
.comment-info {
	color : @text-muted;
	padding-top : 12px;
	text-align : right;
	font-size : 0.9em;
}

.comment-content::v-deep {
	&.ql-editor {
		padding : 0;
	}
	p {
		padding-bottom : 0.5em;
		&.topic {
			font-weight: 600;
		}
	}
	ul {
		padding-left : 0;
		font-size : 0.9em;
	}
	.tag {
		padding : 0 7px;
		display : inline-block;
		margin : 0 4px;
		background : @info-color;
		color : @white;
		border-radius: @border-radius-base;
		font-size : 12px;
		white-spze : nowrap;
	}
}
</style>

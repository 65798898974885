
<template>
	<a-card
		:loading="loading"
		:hoverable="clickable"
		:bordered="bordered"
		:class="cardCssClasses"
		@click="handleClick">
			<div class="mycard-content">
				<div class="ticket-content">
					<div class="row header-row">
						<div class="left">
							<my-router-link v-if="!modalMode" name="ticket/view" :param-value="ticket.id"
								:target="linkTarget" @click.stop>
								{{ticket.ticketNumber}}
							</my-router-link>
							<span v-else class="primary-color-text">{{ticket.ticketNumber}}</span>
						</div>
						<div class="right">
							<TicketStatus
								:status="ticket.status" :reason="ticket.statusReason"
								:trigger="tooltipTrigger"
								:tag-style="true"/>
						</div>
					</div>
					<div class="row">
						<div class="left text-detail">
							{{$dayjs(ticket.eventTime).format("D MMM YY")}}
							<a-icon type="clock-circle" /> {{$dayjs(ticket.eventTime).format("H:mm")}}
						</div>
					</div>
					<div class="row topic-row">
						<span v-trim class="multiline">{{ticket.topic}}</span>
					</div>
				</div>
				<div :class="actionCssClasses">
					<slot name="action"></slot>
				</div>
			</div>
			<TicketPreviewModal
				v-if="modalMode"
				ref="ticketPreview"
				:ticket="ticketData"
        :link-services="linkServices"
				:comment-data-list="commentDataList"
				:link-clickable="linkClickable"/>
	</a-card>
</template>

<script>
import TicketStatus from "@components/ticket/TicketStatus.vue"
import axios from "axios"
import TicketPreviewModal from "./TicketPreviewModal.vue"
import PageMixin from "@mixins/PageMixin.vue"
import { fulfillTicketComment } from '@utils/objectUtil'

export default {
	components : {
		TicketStatus, TicketPreviewModal,
	} ,
	mixins : [PageMixin] ,
	props : {
		ticket : {
			type : null,
			default : () => []
		} ,
		loading : {
			type: Boolean,
			default : false,
		} ,
		bordered : {
			type: Boolean ,
			default : false,
		} ,
		size : {
			type: String,
			default: "",
		} ,
		linkTarget : {
			type : String,
			default : "_self"
		} ,
		clickable :{
			type : Boolean,
			default : false,
		} ,
		linkClickable :{
			type : Boolean,
			default : true,
		} ,
		actived : {
			type : Boolean,
			default : false,
		} ,
		disabled : {
			type : Boolean,
			default : false
		} ,
		modalMode : {
			type : Boolean,
			default : false
		}
	} ,
	data() {
		return {
			ticketData : {},
			linkServices: [],
			commentDataList: [],
		}
	} ,
	computed : {
		cardCssClasses() {
			return [
				"ticket-lite-card","mycard","mycard-columns",
				{
					"mycard-sm" : this.size == 'small' ,
					"mycard-disabled" : this.disabled ,
				}
			]
		} ,
		actionCssClasses() {
			return [
				"mycard-side-actions" ,
				{
					"actived" : this.actived
				}
			]
		} ,
		tooltipTrigger() {
			return this.clickable ? "hover" : "hover|click"
		} ,
		linkNewWindow() {
			return this.linkTarget == '_blank'
		} ,
	} ,
	methods : {
		handleClick(event) {
			if (!this.clickable)
				return;
			if (this.modalMode) {
				this.$refs.ticketPreview.openModal()
				if (!this.ticketData.id) {
					this.fetchTicketData()
				}
			} else  {
				this.$open({name:'ticket/view',params:{id:this.ticket.id}},this.linkTarget)
			}
		} ,
		fetchTicketData() {
			if (!this.ticket.id)
				return
			axios.get("/api/tickets/"+this.ticket.id).then((response) => {
				this.ticketData = response.data.data.ticket
				this.linkServices = response.data.data.link_services
				const dataBucket = response.data.data.bucket

				this.commentDataList = response.data.data.comments.map((comment)=> {
					return fulfillTicketComment(comment, dataBucket)
				})
			}).catch((error)=>{
				this.fetchError(error)
			}).finally(()=>{
				this.hidePageLoading()
			})
		} ,
	}
}
</script>

<style lang="less" scoped>
.ticket-lite-card::v-deep {
	width : 100%;
	.ant-card-body {
		padding: 12px 0 12px 12px;
	}
	.mycard-content {
		position : relative;
		.ticket-content {
			flex-grow : 1;
			padding-right : 12px;
			border-right : 1px solid @border-color-base;
		}
		.mycard-side-actions {
			min-width : 80px;
			text-align : center;
			&.actived {
				background-color : @green-2;
				align-self: stretch;
    		padding-top: 8px;
			}
		}
	}
}

.header-row {
	font-family: @font-family-title;
}
.topic-row {
	margin : 6px 0 8px;
	font-size : 0.95em;
}
.text-detail {
	color : @text-muted;
	font-size : 0.8em;
	vertical-align: middle;
}
.primary-color-text {
	color : @primary-color;
}
</style>

<template>
	<div v-trim class="multiline mytextarea">
		{{value | emptyCheck(emptyMessage)}}
	</div>
</template>

<script>
export default {
	props : {
		value : {
			type : String,
			default : undefined
		} ,
		hideEmpty : {
			type : Boolean ,
			default : false,
		} ,
		emptyMessage : {
			type : String,
			default : undefined,
		}
	}
}
</script>

<style lang="less" scoped>
.mytextarea {
	border : 1px dashed @border-color-base;
	border-radius : @border-radius-base;
	padding : 4px 11px;
}
</style>

<i18n locale="th" lang="yaml">
	part_select.card.part_sku : "SKU : "
	part_select.card.part_name : "ชื่ออะไหล่ : "
	part_select.card.part_name_en : "ชื่ออะไหล่ (EN) : "
	part_select.card.part_name_type : "ประเภท : "
	part_select.card.part_price : "ราคาขาย/หน่วย : "
	part_select.card.select : "เลือกอะไหล่"
</i18n>

<template>
		<a-card
			:loading="loading"
			:hoverable="true"
			:bordered="bordered"
			@click="handleSelectPart">
			<div class="part-card">
				<ImageLoader
					:src="part.image_url"
					class="mycard-image"
					size="tiny"
					border-type="round">
					<a-icon type="deployment-unit" />
				</ImageLoader>
				<div class="part-card-details">
					<div class="part-card-detail">
						<div class="primary-color-text">{{$t('part_select.card.part_sku')}}&nbsp;</div>
						{{ part.sku }}
					</div>
					<div class="part-card-detail">
						<div class="primary-color-text">{{$t('part_select.card.part_name')}}&nbsp;</div>
						{{ displayWrapText(part.nameTh) }}
					</div>
					<div class="part-card-detail">
						<div class="primary-color-text">{{$t('part_select.card.part_name_en')}}&nbsp;</div>
						{{ displayWrapText(part.name) }}
					</div>
				</div>
				<div class="part-card-details-extend">
					<div class="part-card-detail">
						<div class="primary-color-text">{{$t('part_select.card.part_name_type')}}&nbsp;</div>
						{{ part.partType }}
					</div>
					<div class="part-card-detail">
						<div class="primary-color-text">{{$t('part_select.card.part_price')}}&nbsp;</div>
						{{ part.pricePerUnit }}
					</div>
				</div>
			</div>
		</a-card>
</template>

<script>

import ImageLoader from "@components/common/ImageLoader.vue"
import { wrapText } from "@utils/stringUtil"

export default {
	components: {
		ImageLoader,
	},
	props: {
		bordered: {
			type: Boolean ,
			default: true,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		part: {
			type: Object,
			default: () => {}
		},
		onSelectPart: {
			type: Function,
			default: () => {},
		},
	},
	methods: {
		handleSelectPart(){
			this.onSelectPart(this.part.id)
		},
		displayWrapText(text = ''){
			return text ? wrapText(text) : text
		}
	}
}
</script>

<style lang="less" scoped>
.service-part-list{
	height: 300px;
}
.part-card{
	display : flex;
	flex-flow: row;
	gap: 10px;
}
.mycard-image{
	width: 4%;
}
.part-card-details{
	width: 48%;
	display : flex;
	flex-flow: column;
}
.part-card-detail{
	display : flex;
	flex-flow: row;
}
.part-card-details-extend{
	width: 48%;
	display : flex;
	flex-flow: column;
	border-left: 2px solid #d9d9d9;
	padding-left: 5px;
}
.part-card-select-btn{
	align-self: center;
}
.primary-color-text {
	color : @primary-color;
	font-weight: bold;
}
</style>
